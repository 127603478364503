.base {
	display: flex;
	flex-direction: row;
	justify-content: center;

	background: url(./globe.png);
	background-position: 200% 50%;
	background-size: 80%;
	background-repeat: no-repeat;
	background-color: #050d1f;

	.container {
		margin: 7rem 1rem;
		margin-right: 16rem;
		max-width: 1000px;
		display: flex;
		flex-direction: row;

		.advatages {
			display: flex;
			flex-direction: column;
		}

		.advantage {
			@apply text-gray-400;
			display: flex;
			flex-direction: column;
			margin: 2rem 1rem;
			max-width: 36rem;

			.title {
				font-weight: 800;
				font-size: 1.5rem;
				color: white;
			}
			.desc {
				font-weight: 300;
				font-size: 1.2rem;
			}
		}
	}
}

@keyframes rotate {
	from {
		transform: rotateX(0deg);
	}
	to {
		transform: rotateX(360deg);
	}
}

.countdown {
	display: flex;
	height: auto;
	justify-content: space-around;

	.card {
		justify-content: center;
		text-align: center;
		display: flex;
		flex-direction: column;
		animation: rotate 0s 300ms ease-in-out;
		// margin: 0.125rem;

		.cardLabel {
			padding-top: 0.5rem;
			text-transform: uppercase;
			text-align: center;
			font-size: 1rem;
			color: #8486a9;
			display: flex;
			justify-content: center;
		}

		.cardBg {
			position: relative;
			height: 0;
			padding: 1.25rem 2.5rem;
			padding-bottom: 100%;
			background-color: #343650;
			box-shadow: 0 8px 0 1px #191a24;
			border-radius: 10px;
			transition: all 0.175s linear;
			margin: 0.25rem;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: calc(50% - 0.25rem);
				width: 3px;
				height: 6px;
				border-radius: 0 15px 15px 0;
				background-color: #191a24;
				z-index: 10;
			}

			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: calc(50% - 0.25rem);
				width: 3px;
				height: 6px;
				border-radius: 15px 0 0 15px;
				background-color: #191a24;
				z-index: 10;
			}
		}

		.cardNumber {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			display: flex;
			place-content: center;
			place-items: center;
			line-height: 1;
			color: #fb6087;
			font-size: 2.75rem;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 50%;
				background-color: #1e1f29;
				opacity: 0.35;
				z-index: 10;
			}
		}
	}
}

.base {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #050d1f;

	.canvas {
		background: transparent !important;
		object-fit: scale-down;
		width: 100vw;
		margin: auto;
	}

	.container {
		margin: 7rem 1rem;
		margin-top: -5rem;
		display: flex;
		flex-direction: row;
		justify-content: center;

		.advatages {
			display: flex;
			flex-direction: column;
		}

		.advantage {
			@apply text-gray-400;
			display: flex;
			flex-direction: column;
			margin: 2rem 1rem;
			max-width: 36rem;

			.title {
				font-weight: 800;
				font-size: 1.5rem;
				color: white;
			}
			.desc {
				font-weight: 300;
				font-size: 1.2rem;
			}
		}
	}
}

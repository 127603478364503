// @import '../../../styles/base/_variables.scss';

.base {
	// background-color: #5b45b4;
	background-color: rgb(31 38 61);
	color: white;
	box-shadow: 0 0 1.75rem 0 rgba(0, 0, 0, 0.5);
	position: relative;
	padding: 2rem;

	.metrics {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-around;
		flex-wrap: wrap;
		max-width: 80rem;
		margin: auto;
	}
}

.metric {
	padding: 1rem 2rem;

	.val {
		font-size: 3rem;
		font-weight: 600;
		display: inline;
		line-height: 1;
	}

	.unit {
		// color: #2fda35;
		// color: white;
		font-size: 1.5rem;
		margin-left: 0.25rem;
		font-weight: 600;
		line-height: 1;
	}

	.desc {
		font-weight: 400;
		font-size: 2rem;
		max-width: 16rem;
		margin-top: 0.25rem;
		color: #fff;
	}
}

@media (max-width: 680px) {
	.metric {
		margin: 0 3.75rem;
	}
}

@media (max-width: 550px) {
	.metric {
		margin: 0 2.5rem;
	}
}

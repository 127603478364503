// @import '../../../../styles/base/_variables.scss';

// a {
//   color: inherit;
//   -webkit-text-decoration: inherit;
//   text-decoration: inherit;
// }

// [tabindex]:not([tabindex="-1"]), a, button, input, summary {
//   outline-offset: 2px;
//   outline: 2px px solid transparent;
// }

@keyframes rotating {
	0% {
		transform: translate(-50%, -50%) rotate(1turn);
	}
	to {
		transform: translate(-50%, -50%) rotate(0);
	}
}

@media (prefers-reduced-motion) {
	.animatedBorder:before {
		animation: none;
	}
}

.animatedBoarderWrapper {
	--border-radius: 2rem;
	--border-size: 2px;
	--padding: 2px;
	--border-bg: conic-gradient(
		from 180deg at 50% 50%,
		#e92a67 0deg,
		#a853ba 112.5deg,
		#2a8af6 228.75deg,
		rgba(42, 138, 246, 0) 360deg
	);

	margin: 2rem 1rem;

	position: relative;
	overflow: hidden;
	padding: calc(var(--padding) + var(--border-size));
	border-radius: var(--border-radius);
	display: inline-block;
	z-index: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	perspective: 1000;
	transform: translateZ(0);
	transfom: translateZ(0);

	.animatedBorder {
		content: '';
		position: absolute;
		top: var(--border-size);
		right: var(--border-size);
		bottom: var(--border-size);
		left: var(--border-size);
		padding: var(--border-size);
		-webkit-mask: linear-gradient(#fff 0 0) content-box,
			linear-gradient(#fff 0 0);
		mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		z-index: -1;
		border-radius: var(--border-radius);

		&:before {
			content: '';
			display: block;
			background: var(--border-bg);
			box-shadow: 0 0 40px 20px --var(--border-bg);
			width: calc(100% * 1.41421356237);
			padding-bottom: calc(100% * 1.41421356237);
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			border-radius: 100%;
			z-index: -2;
			animation: rotating 5s linear infinite;
		}
	}

	.animatedBoarderContent {
		background: rgba(23, 23, 25, 0.6);
		border-radius: var(--border-radius);
		padding: 0.75rem 1rem;
		font-size: 1rem;
		line-height: 1;
	}
}

.base {
	background-color: hsl(224deg 14% 15%);
	color: #fff;
	padding: 0rem 0rem 10rem;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	min-height: calc(100vh - 8rem);

	// background: purple;
	// background-image: url(https://caymantowing.com/assets/hero-2.2u5.png);
	// background-image:
	//   linear-gradient(
	//     hsl(231deg 48% 7% / 80%),
	//     hsl(262deg 52% 2% / 80%)
	//   ),
	//   url(https://caymantowing.com/assets/hero-2.2u5.png);

	// background-image: url(https://viktorsdk.com/static/gradient-ed7e8ea83472dc4f9da8b668af775eed.png);
	// background-repeat: no-repeat;
	// mix-blend-mode: overlay;
	// background-position: 100% 50%;
	// background-size: cover;

	.bgOverlay {
		position: absolute;
		height: 100vh;
		width: 100vw;
		// background-color: #22273e;
		// background-image: url(https://viktorsdk.com/static/gradient-ed7e8ea83472dc4f9da8b668af775eed.png);
		// background-repeat: no-repeat;
		// mix-blend-mode: overlay;
		// background-size: 110%;
		// background-size: cover;
		pointer-events: none;
	}

	.textbox {
		display: inline-block;
		background: rgba(23, 23, 25, 0.6);
		box-shadow: rgb(0 0 0 / 10%) 0px 4px 4px;
		border-radius: 16px;
		margin: 0.2rem 0px;
		margin-bottom: 2rem;
		padding: 0.5rem 1rem;
		word-break: break-word;
		font-size: 2.3rem;
		font-weight: 700;
	}

	.container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-grow: 1;
	}

	.title {
		margin-top: 5rem;
		font-size: 6rem;
		font-weight: 900;
	}

	.subtitle {
		font-weight: 500;
		font-size: 1.25rem;
		color: #f0f0f0;
		font-weight: 300;
	}

	.ctas {
		// margin-top: 3rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.cta {
			margin: 2rem 1rem;
			padding: 0.75rem 1rem;
			border-radius: 2rem;
			color: white;
			border: 2px solid white;
			font-size: 1rem;
			line-height: 1;
		}
	}

	.twoColumns {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		max-width: 1800px;

		.contentColumn {
			padding-right: 0rem;
			max-width: 66rem;
			box-sizing: border-box;
			text-align: center;
		}
		.imgColumn {
			// display: none;
			width: 48rem;
			justify-content: center;
			align-items: center;
			display: flex;

			.img {
				// box-shadow: 0 .125rem 2rem 0 rgba(24,24,24,.66);
				width: 100%;
			}
		}
	}
}

// @media screen and (min-width: 1100px) and (max-width: 1300px) {
//   .base.base .twoColumns .imgColumn.imgColumn {
//     width: 50rem;
//   }
// }
// @media screen and (min-width: 1300px) and (max-width: 1500px) {
//   .base.base .twoColumns .imgColumn.imgColumn {
//     width: 55rem;
//   }
// }
// @media screen and (min-width: 1500px) and (max-width: 1600px) {
//   .base.base .twoColumns .imgColumn.imgColumn {
//     width: 60rem;
//   }
// }
// @media screen and (min-width: 1600px) and (max-width: 1800px) {
//   .base.base .twoColumns .imgColumn.imgColumn {
//     width: 65rem;
//   }
// }

// .cobrowseBrowser {
//   width: 100%;
// }
// .cobrowseAgent, .cobrowseVisitor {
//   position: relative;
//   height: 8rem;
//   transition: top 1s ease 0s, left 1.5s ease 0.25s;
//   animation: _cobrowseAgent 20s infinite 1s;
//   transform: translate(14rem, -9rem);
// }

// .cobrowseVisitor {
//   transition: top 5s ease 0s, left 5.5s ease 0.25s;
//   animation: _cobrowseAgent 24s infinite 1s;
// }
// .cobrowseVisitor {
//   transition: top 5s ease 0s, left 5.5s ease 0.25s;
//   animation: _cobrowseVisitor 36s infinite 1s;
// }

// @keyframes _cobrowseAgent {
//   0%   { top: 0rem;     left: -0rem;}
//   25% { top: -2rem; left: 0rem;}
//   30% { top: -10rem; left: -2rem;}
//   50% { top: -4rem; left: -8rem;}
//   70% { top: -8rem; left: -7rem;}
//   90% { top: -5rem;   left: -6rem;}
//   100% { top: -0rem;   left: -0rem;}
// }

// @keyframes _cobrowseVisitor {
//   0%   { top: 0rem;     left: -0rem;}
//   20% { top: 1rem; left: -2rem;}
//   40% { top: -2rem; left: -3rem;}
//   50% { top: 1rem; left: -5rem;}
//   60% { top: -6rem; left: -7rem;}
//   80% { top: -8rem;   left: -6rem;}
//   90% { top: -4rem;   left: -0rem;}
//   100% { top: -0rem;   left: -0rem;}
// }

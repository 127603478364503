.base {
	position: relative;
	background-color: #050d1f;
	height: 80vh;
	width: 100vw;
	overflow: hidden;

	.grid {
		position: absolute;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		flex-grow: 0;
		flex-shrink: 0;
		height: 80vh;
		width: 100vw;
		background-color: #22273e;
		// background-image: url(https://viktorsdk.com/static/gradient-ed7e8ea83472dc4f9da8b668af775eed.png);
		background-repeat: no-repeat;
		// mix-blend-mode: overlay;
		background-size: 120%;
		background-position: 0 0;
		filter: blur(3vmax);

		.row {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			.col {
				display: flex;
				flex-grow: 0;
				flex-shrink: 0;
				width: 4vw;
				height: 4vh;
				background: #050d1f;
				transition: opacity 1s 0s ease;
				opacity: 1;

				&:hover {
					opacity: 0;
					transition: opacity 0s 0s ease;
				}
			}
		}
	}

	.container {
		top: 0;
		position: absolute;
		display: flex;
		flex-direction: row;
		color: white;
		pointer-events: none;
		justify-content: center;
		align-items: center;
		margin: auto;
		width: 100vw;
		height: 80vh;

		.contentColumn {
			max-width: 66rem;
			margin-bottom: 4rem;
			text-align: center;
		}

		.textbox {
			display: inline-block;
			background: rgba(23, 23, 25, 0.6);
			box-shadow: rgb(0 0 0 / 10%) 0px 4px 4px;
			border-radius: 16px;
			margin: 0.2rem 0px;
			margin-bottom: 2rem;
			padding: 0.5rem 1rem;
			word-break: break-word;
			font-size: 2.3rem;
			font-weight: 700;
		}

		.title {
			font-size: 4rem;
			font-weight: 900;
		}

		.subtitle {
			font-weight: 500;
			font-size: 1.25rem;
			color: #f0f0f0;
			font-weight: 300;
		}

		.ctas {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			.cta {
				margin: 2rem 1rem;
				padding: 0.75rem 1rem;
				border-radius: 2rem;
				color: white;
				border: 2px solid white;
				font-size: 1rem;
				line-height: 1;
				pointer-events: all;
			}
		}
	}
}

.section {
	color: white;
	background: white;
	width: 100%;
	display: flex;

	.secInner {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		max-width: 64rem;
		margin: 8rem auto;
	}

	.secImg {
		margin-right: 4rem;
		width: 24rem;
	}

	.secInfo {
		.secInfoLead {
			color: #ff3366;
			font-size: 1rem;
			font-weight: 500;
			text-transform: uppercase;
			display: block;
			letter-spacing: 1px;
			margin-bottom: 0.5rem;
		}
		.secInfoTitle {
			font-family: 'Montserrat', sans-serif;
			color: #222;
			font-size: 2.5rem;
			font-weight: 800;
			text-transform: uppercase;
			margin-bottom: 1rem;
			white-space: pre-wrap;
		}
		.secInfoPara,
		.secInfoBullets {
			font-family: 'Roboto', sans-serif;
			font-weight: 282828;
			color: #666;
			font-size: 0.95rem;
			margin-bottom: 1.175rem;
		}
		.secInfoBullets {
			list-style: disc;
			margin-top: 1rem;
			margin-left: 1rem;
			font-size: 0.95rem;
		}
		.secInfoBullet {
		}
	}

	&:nth-child(even) {
		background: #f7f7f7;

		.secInner {
			flex-direction: row-reverse;

			.secImg {
				margin-right: 0rem;
				margin-left: 10rem;
			}
		}
	}
}
